.filter {
  background-color: #FF4F47;
  padding: 5px;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
  width: 1200px;
  margin: 0 auto;
  height: 90px;
}
.filter-title {
  display: inline-block;
  color: white;
  background-color: #f0544c;
  padding: 5px;
  border-radius: 5px;
  border-bottom: 3px solid white;
  font-size: 26px;
}
label {
  height: 100%;
  color: white;
  font-size: 20px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  transition: transform 0.3s;
  cursor: pointer;
}
label:hover {
  transform: scale(1.05);
}
input {
  cursor: pointer;
}

/* --- MEDIA QUERY --- */

@media (max-width: 1250px) {
  .filter {
    width: 90%;
  }
}

@media (max-width: 500px) {
  .filter {
    height: 50px;
    width: 330px;
  }
  .filter-title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 0;
    color: white;
    background-color: transparent;
    border-bottom: 0px;
    font-size: 16px;
  }
  label {
    height: 100%;
    font-size: 10px;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
