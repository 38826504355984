.app {
    background-color: black;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.app-title {
    color: white;
    margin-left: 5%;
}
.movie-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

/* --- MEDIA QUERY --- */

@media (max-width: 500px) {
    .app-title {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
    }
}

